export default (state, action) => {
    switch (action.type) {
        case "UPDATE_AUTH": {
            return {
                ...state,
                authorized: action.payload,
            }
        }
        default: {
            return state;
        }
    }
}