import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Submit.css";
import { useEffect, useState } from "react";
import { sanitize } from "dompurify";
import { Link } from "react-router-dom";
import axios from "axios";
import Courses from "../../core/Courses";
import { Toast } from "bootstrap";
import useLocalStorage from "../../hooks/useLocalStorage";
import dateFormat from "dateformat";

const SubmitArticle = () => {
    const id = localStorage.getItem("employeeID");

    const [success, setSuccess] = useState(false);

    const [subject, setSubject] = useLocalStorage("dft_subject_id_" + id, "");
    const [topic, setTopic] = useLocalStorage("dft_topic_id_" + id, "");
    const [title, setTitle] = useLocalStorage("dft_title_" + id, "");
    const [body, setBody] = useLocalStorage("dft_body_" + id, "");

    const isDraft = subject || topic || title || body;

    const [plainBody, setPlainBody] = useState();
    const [wordCount, setWordCount] = useState(0);

    const [titleFeedback, setTitleFeedback] = useState();
    const [bodyFeedback, setBodyFeedback] = useState();

    const [topicLoading, setTopicLoading] = useState();

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [topics, setTopics] = useState();

    const [selectedSubject, setSelectedSubject] = useLocalStorage("dft_subject_name_" + id, "");
    const [selectedTopic, setSelectedTopic] = useLocalStorage("dft_topic_name_" + id, "");

    const [toast, setToast] = useState();

    useEffect(() => {
        if (!isDraft) {
            localStorage.setItem("dft_creation_time_" + id, dateFormat(new Date(), "dd mmm yyyy"));
        }

        setEditorLoaded(true);

        if (subject) {
            const id = localStorage.getItem("employeeID");

            axios
                .get("/user/" + id + "/topics/" + subject)
                .then(res => {
                    console.log(res.data);
                    setTopics(res.data.topics);
                })
                .catch(e => toast.show())
                .finally(() => setTopicLoading(false));
        }

        const _toast = document.getElementById("submitErrorToast");
        setToast(new Toast(_toast));
    }, []);

    useEffect(() => {
        if (subject) {
            const _selectedSubjectName = Courses
                .filter(group => group.courses.some(row => row.appID == subject))[0]
                .courses
                .filter(course => course.appID == subject)[0].name;
            const _label = Courses
                .filter(group => group.courses.some(row => row.appID == subject))[0].label;

            setSelectedSubject(_selectedSubjectName + " (" + _label + ")");
        }
    }, [subject]);

    useEffect(() => {
        if (topic) {
            if (topics) {
                const _selectedTopicName = topics.filter(element => element.full_topic_id == topic)[0].topic_name;

                setSelectedTopic(_selectedTopicName);
            }
        }
        else setSelectedTopic("")
    }, [topics, topic]);

    const submitArticle = () => {
        setTitleFeedback();
        setBodyFeedback();

        if (!title) {
            setTitleFeedback("Please add a title");
            return;
        }

        if (!plainBody) {
            setBodyFeedback("Please add a body");
            return;
        }

        setSubmitting(true);

        axios
            .post("/articles/submit", {
                userID: id,
                subject: subject,
                topic: topic,
                title: title,
                body: body,
            })
            .then(res => {
                if (res.data.submitted) setSuccess(true);
            })
            .catch(e => toast.show())
            .finally(() => setSubmitting(false));
    }

    const enableTopics = (event) => {
        const value = event.target.value;

        setSubject(value);
        setTopics();
        setTopic("");

        const selectedOption = document.querySelector("#courses option:checked");

        setTopicLoading(true);

        axios
            .get("/user/" + id + "/topics/" + selectedOption.value)
            .then(res => {
                console.log(res.data);
                setTopics(res.data.topics)
            })
            .catch(e => toast.show())
            .finally(() => setTopicLoading(false));
    }

    return (
        success ?
            <div className="text-center px-3 mt-5">
                <h3 className="fw-bold text-success"><i className="bi bi-check2-circle me-2"></i>Success</h3>
                <p className="fw-bold mb-4">Your article was successfully published!</p>
                <Link to={-1} className="btn btn-primary fw-bold px-5 rounded-3">Back to articles</Link>
            </div>
            :
            <>
                <div className="row w-100 gx-0 gx-lg-3 px-3 px-lg-0 pb-4 justify-content-center">
                    <div className="col-12 col-xl-9 pb-2 mb-4 border-bottom">
                        <Link to={-1} className="btn btn-link text-decoration-none fw-bold p-0"><i className="bi bi-chevron-left me-2"></i>Back to articles</Link>
                    </div>
                    <div className="col-12 col-xl-9">
                        <h5 className="mb-3">Submit your article</h5>
                    </div>
                    <div className="col-12 col-lg-5 col-xl-4">
                        <select name="courses" id="courses" className="form-select rounded-3" onChange={e => enableTopics(e)} defaultValue={subject || "default"}>
                            <option value="default" disabled>Select a course...</option>

                            {
                                Courses.map((course, i) => {
                                    return <optgroup key={i} label={course.label}>
                                        {
                                            course.courses.map((single, j) => {
                                                return <option key={j} value={single.appID.toString()}>{single.name}</option>
                                            })
                                        }
                                    </optgroup>
                                })
                            }
                        </select>
                        {
                            topics &&
                            <select name="topic" id="topic" className="form-select rounded-3 mt-3" disabled={!subject} onChange={e => setTopic(e.target.value)} defaultValue={topic || "default"}>
                                <option value="default" disabled>{topicLoading ? "Loading..." : "Select a topic..."}</option>
                                {
                                    topics.map((topic, i) => {
                                        return <option key={i} value={topic.full_topic_id.toString()}>{topic.topic_name}</option>
                                    })
                                }
                            </select>
                        }
                        {
                            !topics && <select className="form-select rounded-3 mt-3" disabled>
                                <option disabled>{topicLoading ? "Loading..." : "Select a topic..."}</option>
                            </select>
                        }
                        <Grammarly clientId="client_UQVtSqvefDQA8gGnjyhc5G">
                            <GrammarlyEditorPlugin>
                                <input type="text" name="title" maxLength="150" className={"form-control my-3 rounded-3 " + (titleFeedback && "is-invalid")}
                                    placeholder="Title of the article..." value={title} onChange={e => setTitle(e.target.value)} />
                                <div className="invalid-feedback">{titleFeedback}</div>
                            </GrammarlyEditorPlugin>
                            <label htmlFor="articleBody" style={{ fontWeight: 600 }} className="mb-1">Aricle Body:</label>
                            <GrammarlyEditorPlugin>
                                {
                                    editorLoaded ? <CKEditor
                                        editor={ClassicEditor}
                                        data={body}
                                        config={{
                                            toolbar: [
                                                'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'link'
                                            ],
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            const plainString = data.replace("</p><p>", " ").replace(/<[^>]+>/g, '');

                                            setBody(data);
                                            setPlainBody(plainString);

                                            if (plainString.trim().split(" ")[0] === "") setWordCount(0);
                                            else setWordCount(plainString.trim().split(" ").length);
                                        }}
                                    /> :
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>

                                }
                            </GrammarlyEditorPlugin>
                        </Grammarly>
                        <div className="d-flex align-items-center">
                            <div className="text-danger small invalid-text">{bodyFeedback}</div>
                            <div className={"words small mt-1 ms-auto " + (wordCount >= 250 ? "text-success" : wordCount > 0 ? "text-danger" : "text-muted")}>Number of words: <span id="show">{
                                wordCount
                            } (250 min.)</span>
                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <button className={
                                "btn btn-primary rounded-3 mt-2 ms-auto fw-bold " +
                                ((submitting || !subject || !topic || !title || !body || wordCount < 250) && "disabled")
                            }
                                onClick={submitArticle}>
                                {
                                    submitting ?
                                        <>
                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </>
                                        :
                                        "Submit Article"
                                }
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 col-xl-5 mt-5 mt-lg-0">
                        <div className="position-relative border rounded-4 p-4" style={{ minHeight: 100 + "%" }}>
                            <p className="small text-muted fw-bold">
                                {(selectedTopic || "Topic") + " - " + (selectedSubject || "Course")}
                            </p>
                            <h1>{title || "Title..."}</h1>
                            <hr />
                            <div className="body" dangerouslySetInnerHTML={{ "__html": sanitize(body) || "Body..." }}></div>
                            <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill text-bg-secondary">Preview</span>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default SubmitArticle;