import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoginScreen from './pages/Login';
import SubmitArticle from './pages/Articles/Submit';
import { useContext, useEffect, useState } from 'react';
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Protected from './routes/Protected';
import { AuthContext } from "./context/AuthProvider";
import { Toast } from 'bootstrap';
import ArticlesIndex from './pages/Articles/Index';
import Home from './pages/Index';
import UpdateArticle from './pages/Articles/Update';

function App() {

  const { updateAuthorization } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);


  useEffect(() => {

    const id = localStorage.getItem("employeeID");

    // console.log(id);

    if (!id) {
      updateAuthorization(false);
      setLoading(false);
      return;
    }

    axios
      .get("/authorize/" + id)
      .then(res => {
        // console.log(res.data);
        if (!res.data.granted) {
          updateAuthorization(false);
          secureLocalStorage.removeItem("refToken");
          secureLocalStorage.removeItem("accToken");
          localStorage.removeItem("employeeID");
          return;
        }

        updateAuthorization(res.data.authorized);
      })
      .catch(e => {
        updateAuthorization(false);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {
        loading ?
          <div className="d-flex flex-column flex-fill vh-100 justify-content-center align-items-center text-center text-muted fw-bold">
            <div className="spinner-border mb-3" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            Loading...
          </div> :
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Navigate to="/login" replace />} />
              <Route element={<Protected section="auth" />}>
                <Route path="login" element={<LoginScreen />} />
              </Route>
              <Route element={<Protected section="main" />}>
                <Route path="articles" element={<Home />}>
                  <Route index element={<ArticlesIndex />} />
                  <Route path="submit" element={<SubmitArticle />} />
                  <Route path="update" element={<UpdateArticle />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
      }
    </>
  );
}

export default App;
