import { createContext, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const initState = {
    authorized: false,
}

export const AuthContext = createContext(initState);

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, initState);

    const updateAuthorization = (authorized) => {
        dispatch({
            type: "UPDATE_AUTH",
            payload: authorized,
        });
    }

    return (
        <AuthContext.Provider value={{
            authorized: state.authorized,
            updateAuthorization,
        }}>
            {children}
        </AuthContext.Provider>
    )
}