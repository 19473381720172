import { useEffect, useState } from "react";
import axios from "axios";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";

const ArticlesIndex = () => {
    const id = localStorage.getItem("employeeID");

    const dftSubjectID = JSON.parse(localStorage.getItem("dft_subject_id_" + id));
    const dftTopicID = JSON.parse(localStorage.getItem("dft_topic_id_" + id));
    const dftTitle = JSON.parse(localStorage.getItem("dft_title_" + id));
    const dftBody = JSON.parse(localStorage.getItem("dft_body_" + id));

    const dftTopicName = JSON.parse(localStorage.getItem("dft_topic_name_" + id));
    const dftCreationTime = localStorage.getItem("dft_creation_time_" + id);

    const [dftAvailable, setDftAvailable] = useState(dftSubjectID || dftTopicID || dftTitle || dftBody);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [articles, setArticles] = useState([]);

    const [modal, setModal] = useState();

    useEffect(() => {
        fetchArticles();

        const _modal = document.getElementById("deleteDraftModal");
        setModal(new Modal(_modal));
    }, []);

    const fetchArticles = () => {

        setLoading(true);
        setError(false);

        axios
            .get("/user/" + id + "/articles")
            .then(res => { organizeData(res.data.articles) })
            .catch(e => setError(true))
            .finally(() => setLoading(false));
    }

    const organizeData = (articles) => {
        if (articles)
            articles.forEach(article => {
                article.submission_time = dateFormat(new Date(article.submission_time.replace(" ", "T") + "Z"), "dd mmm yyyy");
            });
        else articles = [];

        setArticles(articles);
    }

    const deleteDraft = () => {
        localStorage.removeItem("dft_subject_id_" + id);
        localStorage.removeItem("dft_topic_id_" + id);
        localStorage.removeItem("dft_title_" + id);
        localStorage.removeItem("dft_body_" + id);

        localStorage.removeItem("dft_subject_name_" + id);
        localStorage.removeItem("dft_topic_name_" + id);

        modal.hide();
        setDftAvailable(false);
    }

    return (
        <>
            <div className="row gx-0 px-3 justify-content-center mt-5">
                <div className="col-12 col-lg-10 col-xl-8 ">
                    <div className="row g-3 border rounded-4 text-center pt-3 px-3 pb-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="fw-bold text-start">Your articles</h4>
                            <Link to="submit" className="btn btn-primary fw-bold rounded-3"><i className="bi bi-pencil-fill me-2"></i>New</Link>
                        </div>
                        {
                            loading ?
                                <div className="d-flex flex-column align-items-center">
                                    <div className="spinner-border text-muted" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <span className="mt-3 fw-bold text-muted fs-5">Loading...</span>
                                </div> : error ?
                                    <div className="text-center px-3">
                                        <h3 className="fw-bold text-danger"><i className="bi bi-x-octagon-fill me-2"></i>Error</h3>
                                        <p className="fw-bold mb-4">Seems like there was a problem in fetching your articles</p>
                                        <button className="btn btn-outline-success fw-bold px-5 rounded-3" onClick={() => fetchArticles()}>Retry</button>
                                    </div> :
                                    <>
                                        {
                                            dftAvailable && <div className="col-12 col-md-6 col-lg-4">
                                                <div className="d-flex flex-column position-relative border rounded-4 shadow-sm p-3 text-start h-100">
                                                    <p className="small text-muted fw-bold">Topic: {dftTopicName || "Not Selected"}</p>
                                                    <p className="fw-bold fs-5 mb-2">{dftTitle || "No Title Provided"}</p>
                                                    <p className="text-muted small fw-bold mt-auto">Created At: <span className="fs-6">{dftCreationTime}</span></p>
                                                    <hr />
                                                    <div className="d-flex justify-content-between">
                                                        <button className="btn btn-outline-danger btn-sm fw-bold rounded-3" data-bs-toggle="modal" data-bs-target="#deleteDraftModal">
                                                            <i className="bi bi-trash3-fill"></i>
                                                        </button>
                                                        <Link to="submit" className="btn btn-outline-primary btn-sm rounded-3 fw-bold"><i className="bi bi-pencil-fill me-2"></i>Edit</Link>
                                                    </div>
                                                    <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill text-bg-secondary">Draft</span>
                                                </div>
                                            </div>
                                        }
                                        {
                                            articles.length > 0 ?
                                                articles.map((article, i) => {
                                                    return <div key={i} className="col-12 col-md-6 col-lg-4">
                                                        <div className="d-flex flex-column border rounded-4 shadow-sm p-3 text-start h-100">
                                                            <p className="small text-muted fw-bold">Topic: {article.topic_name}</p>
                                                            <p className="fw-bold fs-5 mb-2">{article.title}</p>
                                                            <p className="text-muted small fw-bold mt-auto">Submitted: <span className="fs-6">{article.submission_time}</span></p>
                                                            <hr />
                                                            <div className="d-flex justify-content-end">
                                                                <Link to="update" state={{ article: article }} className="btn btn-outline-primary btn-sm rounded-3 fw-bold"><i className="bi bi-pencil-fill me-2"></i>Edit</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }) :
                                                !dftAvailable && <>
                                                    <h4 className="fw-bold text-muted">
                                                        No articles created yet...
                                                    </h4>
                                                    <div>
                                                        <Link to="submit" className="btn btn-primary fw-bold rounded-3 my-2">Submit your first article</Link>
                                                    </div>
                                                </>
                                        }
                                    </>
                        }
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteDraftModal" tabindex="-1" aria-labelledby="deleteDraftModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-4">
                        <div className="modal-header px-4 py-3 mb-0 border-0">
                            <h1 className="modal-title fs-5 text-danger" id="deleteDraftModalLabel">Delete Draft</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body fw-bold pt-0 px-4">
                            Are you sure you want to delete this draft?
                        </div>
                        <div className="modal-footer border-0 pt-0">
                            <button type="button" className="btn btn-danger fw-bold rounded-3" onClick={deleteDraft}><i className="bi bi-trash3-fill me-2"></i>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ArticlesIndex;