import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";

const Protected = ({ section }) => {
    const { authorized } = useContext(AuthContext);

    if (section === "main") {
        return (
            authorized ? <Outlet /> : <Navigate to="/login" replace />
        );
    }

    return (
        authorized ? <Navigate to="/articles" replace /> : <Outlet />
    )
}

export default Protected;