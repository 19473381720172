import { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import secureLocalStorage from "react-secure-storage";

const Home = () => {
    const navigate = useNavigate();
    const { updateAuthorization } = useContext(AuthContext);

    const logout = () => {
        secureLocalStorage.removeItem("refToken");
        secureLocalStorage.removeItem("accToken");
        localStorage.removeItem("employeeID");
        updateAuthorization(false);
        navigate("/login", { replace: true });
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center px-3 pt-3 mb-4">
                <img src="https://mcqslearn.com/_test/images/logo.webp" alt="MCQsLearn" height={36} />
                <button className="btn btn-outline-danger btn-sm fw-bold rounded-3 opacity-75" onClick={logout}>Log Out</button>
            </div>

            <Outlet />

            <div className="toast-container top-0 end-0 mt-3 mb-5 mb-md-0 p-3 rounded-4">
                <div id="submitErrorToast" className="toast align-items-center text-bg-white rounded-4 border border-danger border-opacity-25 shadow-sm" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                    <div className="d-flex p-2 align-items-center border-bottom text-muted">
                        <div className={"p-2 rounded-circle ms-1 me-2 bg-danger"}></div>
                        <strong className="me-auto text-danger">Error in Submission</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div className="toast-body fw-bold">
                        <p className="mb-1">{
                            navigator.onLine ?
                                "There was an error submitting the article. Please contact the authority to address this issue." :
                                "Your internet connection seems to be halted"
                        }</p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Home;