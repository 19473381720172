export default [
    {
        label: "GRADE 9",
        courses: [
            {
                appID: 101105,
                name: "Biology",
            },
            {
                appID: 102103,
                name: "Chemistry",
            },
            {
                appID: 103103,
                name: "Physics",
            },
        ],
    },
    {
        label: "GRADE 10",
        courses: [
            {
                appID: 101106,
                name: "Biology",
            },
            {
                appID: 102104,
                name: "Chemistry",
            },
            {
                appID: 103104,
                name: "Physics",
            },
        ],
    },
    {
        label: "O LEVEL",
        courses: [
            {
                appID: 101101,
                name: "Biology",
            },
            {
                appID: 102101,
                name: "Chemistry",
            },
            {
                appID: 103101,
                name: "Physics",
            },
        ],
    },
    {
        label: "A LEVEL",
        courses: [
            {
                appID: 101102,
                name: "Biology",
            },
            {
                appID: 102102,
                name: "Chemistry",
            },
            {
                appID: 103102,
                name: "Physics",
            },
        ],
    },
    {
        label: "COLLEGE",
        courses: [
            {
                appID: 101107,
                name: "Biology",
            },
            {
                appID: 102105,
                name: "Chemistry",
            },
            {
                appID: 103105,
                name: "Physics",
            },
            {
                appID: 106105,
                name: "Mathematics",
            },
        ],
    },
    {
        label: "BIOLOGY COURSES",
        courses: [
            {
                appID: 101103,
                name: "Histology",
            },
            {
                appID: 101104,
                name: "Microbiology",
            },
            {
                appID: 101108,
                name: "Cell Biology",
            },
            {
                appID: 101109,
                name: "Molecular Biology",
            },
            {
                appID: 101110,
                name: "Metabolism",
            },
            {
                appID: 101111,
                name: "Phylum",
            },
            {
                appID: 101112,
                name: "Zoology",
            },
        ],
    },
    {
        label: "BUSINESS ADMINISTRATION (BBA)",
        courses: [
            {
                appID: 104101,
                name: "Human Resource Management",
            },
            {
                appID: 104103,
                name: "Marketing Management",
            },
            {
                appID: 104104,
                name: "Financial Management",
            },
            {
                appID: 104105,
                name: "Financial Markets",
            },
            {
                appID: 104106,
                name: "Business Statistics",
            },
            {
                appID: 104107,
                name: "Principles of Marketing",
            },
            {
                appID: 104108,
                name: "Cost Accounting",
            },
            {
                appID: 104110,
                name: "Business Mathematics",
            },
            {
                appID: 104113,
                name: "Business Economics",
            },
        ],
    },
    {
        label: "BUSINESS ADMINISTRATION (MBA)",
        courses: [
            {
                appID: 104102,
                name: "Human Resource Management",
            },
            {
                appID: 104109,
                name: "Business Statistics",
            },
            {
                appID: 104111,
                name: "Organizational Development",
            },
            {
                appID: 104112,
                name: "Project Management",
            },
        ],
    },
    {
        label: "COMPUTER SCIENCE (BSCS)",
        courses: [
            {
                appID: 105101,
                name: "Database Management Systems",
            },
            {
                appID: 105103,
                name: "Digital Logic Design",
            },
            {
                appID: 105104,
                name: "Operating Systems",
            },
            {
                appID: 105105,
                name: "Semantic Web",
            },
            {
                appID: 105106,
                name: "Computer Fundamentals",
            },
            {
                appID: 105107,
                name: "Computer Networks",
            },
            {
                appID: 105108,
                name: "Digital Image Processing",
            },
            {
                appID: 105109,
                name: "Computer Architecture",
            },
            {
                appID: 105110,
                name: "Computer Basics",
            },
        ],
    },
    {
        label: "COMPUTER SCIENCE (MSCS)",
        courses: [
            {
                appID: 105102,
                name: "Database Management Systems",
            },
        ],
    },
    {
        label: "ENGINEERING",
        courses: [
            {
                appID: 201101,
                name: "Engineering Mathematics",
            },
            {
                appID: 201102,
                name: "Engineering Physics",
            },
            {
                appID: 202101,
                name: "Digital Electronics",
            },
            {
                appID: 202102,
                name: "Electronic Devices",
            },
            {
                appID: 202103,
                name: "Electromagnetic Theory",
            },
            {
                appID: 202104,
                name: "Electric Circuit Analysis",
            },
            {
                appID: 202105,
                name: "Electronic Circuit Design",
            },
        ],
    },
    {
        label: "DEGREE PROGRAMS",
        courses: [
            {
                appID: 301101,
                name: "Biological Sciences",
            },
            {
                appID: 301102,
                name: "Global Warming",
            },
            {
                appID: 301103,
                name: "Marine Pollution",
            },
            {
                appID: 301104,
                name: "Psychology",
            },
            {
                appID: 301105,
                name: "Sociology",
            },
            {
                appID: 301106,
                name: "General Knowledge",
            },
            {
                appID: 301107,
                name: "International Relations",
            },
            {
                appID: 301108,
                name: "Anthropology Basics",
            },
            {
                appID: 301109,
                name: "Human Diversity",
            },
        ],
    },
    {
        label: "MISC",
        courses: [
            {
                appID: 999001,
                name: "Technology"
            },
            {
                appID: 999002,
                name: "Online Learning"
            },
            {
                appID: 999003,
                name: "Degree Programs"
            }
        ]
    }
];