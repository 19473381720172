import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";
import Config from './core/Config';
import secureLocalStorage from "react-secure-storage";
import { AuthProvider } from './context/AuthProvider';

axios.defaults.baseURL = Config.baseURL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Auth-Acc-Token": secureLocalStorage.getItem("accToken"),
      "Auth-Ref-Token": secureLocalStorage.getItem("refToken"),
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    if (response.data.tokenState === "exp") {
      secureLocalStorage.setItem("accToken", response.data.accToken);
    }

    return response;
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <App />
  </AuthProvider>
  // </React.StrictMode>
);
reportWebVitals();
