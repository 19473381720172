import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Submit.css";
import { useEffect, useState } from "react";
import { sanitize } from "dompurify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Courses from "../../core/Courses";
import { Modal, Toast } from "bootstrap";

const UpdateArticle = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [subject, setSubject] = useState();
    const [topic, setTopic] = useState();
    const [title, setTitle] = useState();
    const [body, setBody] = useState();
    const [plainBody, setPlainBody] = useState();
    const [wordCount, setWordCount] = useState(0);

    const [titleFeedback, setTitleFeedback] = useState();
    const [bodyFeedback, setBodyFeedback] = useState();

    const [topicLoading, setTopicLoading] = useState();

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [topics, setTopics] = useState();

    const [selectedSubject, setSelectedSubject] = useState();
    const [selectedTopic, setSelectedTopic] = useState();

    const [article, setArticle] = useState();

    const [toast, setToast] = useState();
    const [modal, setModal] = useState();

    useEffect(() => {
        setEditorLoaded(true);
        fetchArticle();

        const _toast = document.getElementById("submitErrorToast");
        setToast(new Toast(_toast));

        const _modal = document.getElementById("backWarningModal");
        setModal(new Modal(_modal));
    }, []);

    const fetchArticle = () => {
        const userID = localStorage.getItem("employeeID");

        setLoading(true);
        setError(false);

        axios
            .get("/user/" + userID + "/articles/" + state.article.id)
            .then(res => {
                console.log(res.data);
                setValues(res.data.article);
            })
            .catch(e => setError(true))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (subject) {
            const _selectedSubjectName = Courses
                .filter(group => group.courses.some(row => row.appID == subject))[0]
                .courses
                .filter(course => course.appID == subject)[0].name;
            const _label = Courses
                .filter(group => group.courses.some(row => row.appID == subject))[0].label;

            setSelectedSubject(_selectedSubjectName + " (" + _label + ")");
        }
    }, [subject]);

    useEffect(() => {
        if (topic) {
            if (topics) {
                const _selectedTopicName = topics.filter(element => element.full_topic_id == topic)[0].topic_name;

                setSelectedTopic(_selectedTopicName);
            }
        }
        else setSelectedTopic("")
    }, [topics, topic]);

    const setValues = (article) => {
        setSubject(article.app_id);
        setTopic(article.topic_id);
        setTitle(article.title);
        setBody(article.body);

        setArticle(article);

        const plainString = article.body.replace("</p><p>", " ").replace(/<[^>]+>/g, '');
        setPlainBody(plainString);

        if (plainString.trim().split(" ")[0] === "") setWordCount(0);
        else setWordCount(plainString.trim().split(" ").length);

        getTopics(article.author, article.app_id);
    }

    const submitArticle = () => {
        setTitleFeedback();
        setBodyFeedback();

        if (!title) {
            setTitleFeedback("Please add a title");
            return;
        }

        if (!plainBody) {
            setBodyFeedback("Please add a body");
            return;
        }

        setSubmitting(true);

        const userID = localStorage.getItem("employeeID");

        axios
            .post("/articles/update", {
                userID: userID,
                articleID: article.id,
                subject: subject,
                topic: topic,
                title: title,
                body: body,
            })
            .then(res => {
                // console.log(res.data);
            })
            .catch(e => toast.show())
            .finally(() => setSubmitting(false));
    }

    const enableTopics = (event) => {
        const value = event.target.value;

        setSubject(value);

        const selectedOption = document.querySelector("#courses option:checked");
        const label = selectedOption.closest("optgroup").getAttribute("label");
        const selectText = selectedOption.textContent;

        event.target.blur();
        selectedOption.textContent = selectText + " (" + label + ")";

        setTopicLoading(true);

        const id = localStorage.getItem("employeeID");

        getTopics(id, selectedOption.value);
    }

    const getTopics = (userID, appID) => {
        axios
            .get("/user/" + userID + "/topics/" + appID)
            .then(res => {
                // console.log(res.data);
                setTopics(res.data.topics)
            })
            .catch(e => toast.show())
            .finally(() => setTopicLoading(false));
    }

    const goBack = () => {
        modal.hide();
        navigate(-1);
    }

    return (
        <>
            {
                loading ?
                    <div className="d-flex flex-column flex-fill align-items-center justify-content-center text-muted">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <span className="fw-bold mt-3">Loading...</span>
                    </div>
                    : error ?
                        <div className="text-center px-3 mt-5">
                            <h3 className="fw-bold text-danger"><i className="bi bi-x-octagon-fill me-2"></i>Error</h3>
                            <p className="fw-bold mb-4">Seems like there was a problem in fetching your article</p>
                            <button className="btn btn-outline-primary fw-bold px-5 rounded-3" onClick={() => fetchArticle()}>Retry</button>
                        </div>
                        : <>
                            <div className="row w-100 gx-0 gx-lg-3 px-3 px-lg-0 pb-4 justify-content-center">
                                <div className="col-12 col-xl-9 pb-2 mb-4 border-bottom">
                                    <button className="btn btn-link text-decoration-none fw-bold p-0" data-bs-toggle="modal" data-bs-target="#backWarningModal"><i className="bi bi-chevron-left me-2"></i>Back to articles</button>
                                </div>
                                <div className="col-12 col-xl-9">
                                    <h5 className="mb-3">Update your article</h5>
                                </div>
                                <div className="col-12 col-lg-5 col-xl-4">
                                    <select name="courses" id="courses" className="form-select rounded-3" defaultValue={subject} onChange={e => enableTopics(e)}>
                                        <option selected disabled>Select a course...</option>

                                        {
                                            Courses.map((course, i) => {
                                                return <optgroup key={i} label={course.label}>
                                                    {
                                                        course.courses.map((single, j) => {
                                                            return <option key={j} value={single.appID.toString()}>{single.name}</option>
                                                        })
                                                    }
                                                </optgroup>
                                            })
                                        }
                                    </select>
                                    {
                                        topics &&
                                        <select name="topic" id="topic" className="form-select rounded-3 mt-3" disabled={!subject} onChange={e => setTopic(e.target.value)} defaultValue={topic || "default"}>
                                            <option value="default" disabled>{topicLoading ? "Loading..." : "Select a topic..."}</option>
                                            {
                                                topics.map((topic, i) => {
                                                    return <option key={i} value={topic.full_topic_id.toString()}>{topic.topic_name}</option>
                                                })
                                            }
                                        </select>
                                    }
                                    {
                                        !topics && <select className="form-select rounded-3 mt-3" disabled>
                                            <option disabled>{topicLoading ? "Loading..." : "Select a topic..."}</option>
                                        </select>
                                    }
                                    <Grammarly clientId="client_UQVtSqvefDQA8gGnjyhc5G">
                                        <GrammarlyEditorPlugin>
                                            <input type="text" name="title" defaultValue={title} maxLength="150" className={"form-control my-3 rounded-3 " + (titleFeedback && "is-invalid")}
                                                placeholder="Title of the article..." onChange={e => setTitle(e.target.value)} />
                                            <div className="invalid-feedback">{titleFeedback}</div>
                                        </GrammarlyEditorPlugin>
                                        <label htmlFor="articleBody" style={{ fontWeight: 600 }} className="mb-1">Aricle Body:</label>
                                        <GrammarlyEditorPlugin>
                                            {
                                                editorLoaded ? <CKEditor
                                                    editor={ClassicEditor}
                                                    data={body}
                                                    config={{
                                                        toolbar: [
                                                            'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'link'
                                                        ],
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        const plainString = data.replace("</p><p>", " ").replace(/<[^>]+>/g, '');

                                                        setBody(data);
                                                        setPlainBody(plainString);

                                                        if (plainString.trim().split(" ")[0] === "") setWordCount(0);
                                                        else setWordCount(plainString.trim().split(" ").length);
                                                    }}
                                                /> :
                                                    <div className="text-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>

                                            }
                                        </GrammarlyEditorPlugin>
                                    </Grammarly>
                                    <div className="d-flex align-items-center">
                                        <div className="text-danger small invalid-text">{bodyFeedback}</div>
                                        <div className={"words small mt-1 ms-auto " + (wordCount >= 250 ? "text-success" : wordCount > 0 ? "text-danger" : "text-muted")}>Number of words: <span id="show">{
                                            wordCount
                                        } (250 min.)</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <button className={
                                            "btn btn-primary rounded-3 mt-2 ms-auto fw-bold " +
                                            ((submitting || !subject || !topic || !title || !body || wordCount < 250) && "disabled")
                                        }
                                            onClick={submitArticle}>
                                            {
                                                submitting ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </>
                                                    :
                                                    "Update Article"
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 col-xl-5 mt-4 mt-lg-0">
                                    <div className="position-relative border rounded-4 p-4" style={{ minHeight: 100 + "%" }}>
                                        <p className="small text-muted fw-bold">
                                            {(selectedTopic || "Topic") + " - " + (selectedSubject || "Course")}
                                        </p>
                                        <h1>{title || "Title..."}</h1>
                                        {/* <h5 className="text-muted">{subtitle || "Subtitle..."}</h5> */}
                                        <hr />
                                        <div className="body" dangerouslySetInnerHTML={{ "__html": sanitize(body) || "Body..." }}></div>
                                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill text-bg-secondary">Preview</span>
                                    </div>
                                </div>
                            </div>
                        </>
            }

            <div className="modal fade" id="backWarningModal" tabindex="-1" aria-labelledby="backWarningModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-4">
                        <div className="modal-header px-4 py-3 mb-0 border-0">
                            <h1 className="modal-title fs-5 text-danger" id="backWarningModalLabel">Changes at stake</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body fw-bold pt-0 px-4">
                            All your changes will be lost if you opt to go back.
                        </div>
                        <div className="modal-footer border-0 pt-0">
                            <button type="button" className="btn btn-danger fw-bold rounded-3" onClick={goBack}>Go Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateArticle;