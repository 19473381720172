import { validateEmail } from "../helpers/Validator";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Toast } from "bootstrap";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { AuthContext } from "../context/AuthProvider";

const LoginScreen = () => {
    const navigate = useNavigate();

    const { updateAuthorization } = useContext(AuthContext);

    const [emailFeedback, setEmailFeedback] = useState("");
    const [passwordFeedback, setPasswordFeedback] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [email, setEmail] = useState("");

    const [networkToast, setNetworkToast] = useState();
    const [accessToast, setAccessToast] = useState();

    useEffect(() => {
        const _networkToast = document.getElementById("networkErrorToast");
        const _accessToast = document.getElementById("restrictedToast");

        setNetworkToast(new Toast(_networkToast));
        setAccessToast(new Toast(_accessToast));
    }, []);

    const validateUser = (event) => {
        event.preventDefault();
        event.stopPropagation();


        // console.log(networkToast);
        // console.log(accessToast);

        setEmailFeedback("");
        setPasswordFeedback("");

        const formData = event.target.elements;

        const email = formData.email.value.trim();
        const password = formData.password.value.trim();

        setEmail(email);

        if (!email) {
            // console.log("Email empty");
            setEmailFeedback("Please enter your email address");
            return;
        } else if (!validateEmail(email)) {
            // console.log("Invalid Email");
            setPasswordFeedback("Please enter a valid email address");
            return;
        }

        if (!password) {
            // console.log("Password Empty");
            setPasswordFeedback("Please enter your password");
            return;
        }

        setLoading(true);

        axios.post("/login", {
            email: email,
            password: password,
        })
            .then(res => {
                console.log(res.data);
                if (!res.status === 200) {
                    throw Error();
                }

                if (!res.data.exists) {
                    // console.log("User doesn't exist");
                    setEmailFeedback("This email address is not registered");
                    return;
                }

                if (!res.data.granted) {
                    // console.log("Permission denied");
                    accessToast.show();
                    return;
                }

                if (!res.data.matched) {
                    // console.log("Invalid password");
                    setPasswordFeedback("This password is incorrect");
                    return;
                }

                localStorage.setItem("employeeID", res.data.employee.id);
                secureLocalStorage.setItem("refToken", res.data.refToken);
                secureLocalStorage.setItem("accToken", res.data.accToken);

                updateAuthorization(true);

                navigate("/articles", { replace: true });
            })
            .catch(e => networkToast.show())
            .finally(() => setLoading(false));
    }

    return (
        <>
            <div className="row mx-0 w-100 vh-100">
                <div className="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto">
                    <div className="d-flex flex-column h-100 justify-content-center">
                        <form onSubmit={validateUser}>
                            <div className="d-flex justify-content-between align-items-end">
                                <h4 className="fw-bold mb-0">Team</h4>
                                <img src="https://mcqslearn.com/_test/images/logo.webp" alt="MCQsLearn" height={36} />
                            </div>
                            <hr />
                            <label htmlFor="email" className="fw-bold mb-1">Email Address</label>
                            <input type="email" id="email" className={"form-control rounded-3 fw-bold " + (emailFeedback && "is-invalid")} />
                            <div className="invalid-feedback fw-bold">{emailFeedback}</div>
                            <label htmlFor="password" className="fw-bold mt-3 mb-1">Password</label>
                            <input type="password" id="password" className={"form-control rounded-3 fw-bold " + (passwordFeedback && "is-invalid")} />
                            <div className="invalid-feedback fw-bold">{passwordFeedback}</div>
                            <div className="d-flex justify-content-end">
                                <button className={"btn btn-outline-primary rounded-3 fw-bold mt-4 w-100 " + (loading && "disabled")}>
                                    {
                                        loading ? <><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            Loading...</> : "Login"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="toast-container top-0 end-0 mt-3 mb-5 mb-md-0 p-3 rounded-4">
                <div id="networkErrorToast" className="toast align-items-center text-bg-white rounded-4 border border-danger border-opacity-25 shadow-sm" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                    <div className="d-flex p-2 align-items-center border-bottom text-muted">
                        <div className={"p-2 rounded-circle ms-1 me-2 bg-danger"}></div>
                        <strong className="me-auto text-danger">Authentication Failed</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div className="toast-body fw-bold">
                        There was an unexpected error, plese try again in a while.
                    </div>
                </div>
            </div>

            <div className="toast-container top-0 end-0 mt-3 mb-5 mb-md-0 p-3 rounded-4">
                <div id="restrictedToast" className="toast align-items-center text-bg-white rounded-4 border border-danger border-opacity-25 shadow-sm" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                    <div className="d-flex p-2 align-items-center border-bottom text-muted">
                        <div className={"p-2 rounded-circle ms-1 me-2 bg-danger"}></div>
                        <strong className="me-auto text-danger">Access Denied</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div className="toast-body fw-bold">
                        <p className="mb-1">Employee with email address: <code>{email}</code> has been denied access to the portal due to contract expiry.</p>
                        <p className="small text-muted mb-0">In case, your contract is still valid, please contact us at <a href="mailto:techleansedu@gmail.com">techleansedu@gmail.com</a></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginScreen;